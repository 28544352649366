"use client";

import { Stack } from "@chakra-ui/react";
import { Box, Text, HStack } from "@/components/ChakraUiManager";
import "./DocCenterPageHeader.scss";
import { formatDateDocCenter } from "@/utils/common";
import { shareIconDoc, clockIcon } from "public/assets";
import Image from "next/image";
import { GenericPdfDownloader } from "@/components/common";

type DocCenterPageHeaderProps = {
  documentTitle: string | null;
  dateString: string;
  readTime: number;
};

const ShareComponent = (props: any) => {
  const encodedUrl = encodeURIComponent(props.docUrl);

  return (
    <Box className="doc-share">
      <a href={`mailto:?body=${encodedUrl}`}>
        <HStack>
          <Image
            src={shareIconDoc.src}
            alt={"share icon button"}
            width={18}
            height={18}
          />
          <Text className="doc-share-text">Share</Text>
        </HStack>
      </a>
    </Box>
  );
};

const DocCenterPageHeader = (props: DocCenterPageHeaderProps) => {
  const { documentTitle, dateString, readTime } = props;
  const pubDate = formatDateDocCenter(new Date(dateString));
  const currentUrl = window.location.href;

  return (
    <>
      <title>{documentTitle}</title>
      <div className="doc-page-header">
        <Stack spacing={8}>
          <Stack spacing={8} direction="row" align="center">
            {pubDate && <div>Published on {pubDate}</div>}
            <Stack direction="row">
              <Image
                src={clockIcon}
                alt={"share icon button"}
                width={18}
                height={18}
              />
              <div>{readTime} min read</div>
            </Stack>
          </Stack>
          <Stack spacing={8} direction="row" align="center">
            <GenericPdfDownloader
              downloadFileName={`${documentTitle}.pdf`}
              rootElementId="doc-center-overview"
            />
            <ShareComponent docUrl={currentUrl} />
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export default DocCenterPageHeader;
