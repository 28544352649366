"use client";
import { ReactNode, RefObject } from "react";

import { Box, Flex, IconButton, Button } from "../ChakraUiManager";

import "./TopMenuManager.scss";

import { SearchIcon } from "@chakra-ui/icons";
import { useUserAuthenticated, useUserInfo } from "@/app/providers";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";
import { getSelectedOrg, getAppConfig, getOrganizations } from "@/store";

import { CustomHit } from "@/utils/algolia";

import algoliasearch from "algoliasearch";

import {
  InstantSearch,
  SearchBox,
  SearchBoxProps,
  useInstantSearch,
  Hits,
  useHits,
  Configure,
} from "react-instantsearch";
import { vector, vectorSelected } from "public/assets";

import { finxactLogoWhite } from "public/assets";
import OrgEnvDropdownWrapper from "../common/OrgEnvSelector/OrgEnvDropdownWrapper";
import { useIsPermitted } from "@/utils";
import { Header } from "@finxact/finxact-shared-ui";

interface NoResultsBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
  pathName: string | null;
}

export default function TopMenuManager() {
  const { user } = useUserInfo();
  const userAuthenticated = useUserAuthenticated();
  const selectedOrg = useSelector(getSelectedOrg);
  const userOrgs = useSelector(getOrganizations);
  const isPermitted = useIsPermitted();
  const appConfig = useSelector(getAppConfig);
  const pathName = usePathname();

  const isTeaser = !!(
    pathName &&
    (pathName === "" ||
      pathName === "/" ||
      pathName.startsWith("/solutions") ||
      pathName.startsWith("/profile-setup"))
  );

  if (!appConfig) {
    return null;
  }

  return (
    <Header
      chatClosedIcon={vector.src}
      chatOpenIcon={vectorSelected.src}
      consoleConfig={appConfig}
      pathName={pathName ?? ""}
      isPermitted={isPermitted}
      defaultLogo={finxactLogoWhite.src}
      isTeaser={isTeaser}
      organization={selectedOrg}
      orgEnvSelector={<OrgEnvDropdownWrapper/>}
      primaryOrg={
        userAuthenticated && userOrgs[user.organization]
          ? userOrgs[user.organization]
          : undefined
      }
      searchBar={SearchBar}
      user={userAuthenticated ? user : undefined}
    />
  );
}

function SearchBar(props: { innerRef?: RefObject<HTMLDivElement> }) {
  const appConfig = useSelector(getAppConfig);
  const pathName = usePathname();

  const queryHook: SearchBoxProps["queryHook"] = (query, search) => {
    search(query);
  };

  const searchClient = algoliasearch(
    appConfig?.searchAppId!,
    appConfig?.searchKey!,
  );
  return (
    <div ref={props.innerRef}>
      <InstantSearch
        indexName={appConfig?.searchIndex}
        searchClient={searchClient}
      >
        <Flex className="global-search" alignItems="center">
          <Box className="search-container-expanded">
            <IconButton
              aria-label="Search"
              icon={<SearchIcon />}
              variant="ghost"
              className="nav-search-icon"
            />
            <SearchBox
              placeholder="Search across Console, Finxact Academy, & API References"
              classNames={{
                root: "global-search-input",
                form: "global-search-input",
                input: "global-search-input",
                submitIcon: "display-none",
              }}
              queryHook={queryHook}
              searchAsYouType={true}
            />
            <NoResultsBoundary pathName={pathName} fallback={<NoResults />}>
              <Flex className="search-results"></Flex>
            </NoResultsBoundary>
            <Configure hitsPerPage={100} />
          </Box>
        </Flex>
      </InstantSearch>
    </div>
  );
}

function NoResultsBoundary({
  children,
  fallback,
  pathName,
}: NoResultsBoundaryProps) {
  const { results, uiState, indexUiState } = useInstantSearch();
  const appConfig = useSelector(getAppConfig);

  if (!indexUiState.query || !appConfig?.searchIndex) {
    return "";
  }

  const searchQuery = uiState[appConfig?.searchIndex].query;

  if (!results.hits.length && results.nbHits === 0) {
    return fallback;
  }

  const path = pathName;

  const extractPart = (pathname: string) => {
    const parts = pathname.split("/").filter(Boolean);

    if (parts[0] === "algolia-search") {
      return null;
    }

    if (parts[1] === "tutorials") {
      return parts[1];
    }

    return parts[0] || "";
  };

  const slug = extractPart(pathName || "");

  const href = slug
    ? `/algolia-search?searchQuery=${searchQuery}&slug=${slug}`
    : `/algolia-search?searchQuery=${searchQuery}`;

  return (
    <Flex className="search-results">
      <CustomHit slug={slug} useHits={useHits} location="topmenu" />
      <Box display="flex" justifyContent="end" className="btn-view-all-results">
        <a href={href}>
          <Button className="app-btn-reg-secondary-transparent">
            View All Results
          </Button>
        </a>
      </Box>
    </Flex>
  );
}

function NoResults() {
  const { setUiState, uiState } = useInstantSearch();
  return (
    <Flex className="search-results flex-acenter">
      <p className="no-results">No results found.</p>
    </Flex>
  );
}
