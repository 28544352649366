import { SidebarManagerProp } from "@/components/sidebar-manager/SidebarManager";
import { ROUTE_BASE_URL, ROUTE_PATH } from "@/route-config/route-path";
import { MultiTieredSideMenuDataModel } from "@/components/common/MultiTieredSideMenu/MultiTieredSideMenuDataModel";
import { docCenterSidemenuIconHome, spanner } from "public/assets";
const SIDEBAR_MENU = {
  menuHeading: "",
  menuList: [
    {
      title: "",
      menuItemList: [
        {
          name: "Overview",
          imgUrl: "",
          isSubmenuPresent: false,
        },
        {
          name: "Product Launchpad",
          imgUrl: "",
          isSubmenuPresent: false,
        },
      ],
    },
    {
      title: "Configuration",
      menuItemList: [
        {
          name: "Product",
          imgUrl: "",
          isSubmenuPresent: true,
        },
        {
          name: "General ledger",
          imgUrl: "",
          isSubmenuPresent: true,
        },
        {
          name: "Bank",
          imgUrl: "",
          isSubmenuPresent: true,
        },
      ],
    },
    {
      title: "Utilities",
      menuItemList: [
        {
          name: "Frequency modeler",
          imgUrl: "",
          isSubmenuPresent: false,
        },
        {
          name: "Clear cached core config",
          imgUrl: "",
          isSubmenuPresent: false,
        },
        {
          name: "TGUID",
          imgUrl: "",
          isSubmenuPresent: true,
        },
      ],
    },
  ],
};

const ACADEMY_SIDEBAR_MENU = (isSigned: boolean): SidebarManagerProp => ({
  homeTitle: isSigned ? "My learning profile" : "Academy home",
  homeHref: ROUTE_BASE_URL.ACADEMY,
  menuList: isSigned
    ? [
        {
          name: "Catalog",
          enableAccordion: false,
          subMenu: [],
          href: `${ROUTE_BASE_URL.ACADEMY}/catalog`,
        },
        {
          name: "Badges & certifications",
          enableAccordion: false,
          subMenu: [],
          href: `${ROUTE_BASE_URL.ACADEMY}/badges-certifications`,
        },
      ]
    : [],
});

const ACADEMY_SIDEBAR_MENU_MULTI: MultiTieredSideMenuDataModel[] = [
  {
    type: "none",
    title: "",
    path: "",
    isSelected: true,
    uid: "",
    childrens: [
      {
        title: "My Learning",
        uid: ROUTE_BASE_URL.ACADEMY,
        childrens: [],
        path: ROUTE_BASE_URL.ACADEMY,
        type: "item",
      },
      {
        title: "Academy Overview",
        uid: ROUTE_PATH.ACADEMY_OVERVIEW,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_OVERVIEW,
        type: "item",
      },
      {
        title: "Catalog",
        uid: ROUTE_PATH.ACADEMY_CATALOG,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_CATALOG,
        type: "item",
      },
      {
        title: "Badges & certifications",
        uid: ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION,
        childrens: [],
        path: ROUTE_PATH.ACADEMY_BADGES_CERTIFICATION,
        type: "item",
      },
    ],
  },
];

const Global_SIDEBAR_MENU: MultiTieredSideMenuDataModel[] = [
  {
    type: "none",
    title: "",
    path: "",
    isSelected: true,
    uid: "",
    childrens: [
      {
        type: "item",
        icon: docCenterSidemenuIconHome,
        title: "Home",
        childrens: [],
        path: ROUTE_BASE_URL.DASHBOARD_PATH,
        uid: ROUTE_BASE_URL.DASHBOARD_PATH,
      },
      {
        title: "Foundational setup",
        uid: "Foundational Setup",
        path: "",
        type: "accordion",
        childrens: [
          {
            title: "Institutional configuration",
            uid: ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION,
            childrens: [],
            path: `${ROUTE_BASE_URL.INSTITUTIONAL_CONFIGURATION}`,
            type: "item",
          },
          {
            title: "General ledger",
            uid: ROUTE_BASE_URL.GENERAL_LEDGER,
            childrens: [],
            path: `${ROUTE_BASE_URL.GENERAL_LEDGER}`,
            type: "item",
          },
          {
            title: "Transaction codes",
            uid: ROUTE_BASE_URL.TRANS_CODE,
            childrens: [],
            path: `${ROUTE_BASE_URL.TRANS_CODE}`,
            type: "item",
          },
          {
            title: "Network",
            uid: ROUTE_BASE_URL.NETWORK,
            childrens: [],
            path: `${ROUTE_BASE_URL.NETWORK}`,
            type: "item",
          },
          {
            title: "Customer relationship types",
            uid: ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE,
            childrens: [],
            path: `${ROUTE_BASE_URL.CUSTOMER_RELATIONSHIP_TYPE}`,
            type: "item",
          },
        ],
      },
      {
        title: "Product Configuration",
        uid: "Product Configuration",
        path: "",
        type: "header",
        childrens: [],
      },
      {
        title: "Product launchpad",
        uid: ROUTE_PATH.PRODUCT_LAUNCHPAD,
        childrens: [],
        path: `${ROUTE_PATH["PRODUCT_LAUNCHPAD"]}`,
        type: "item",
        coreOperation: "Prod_bkList",
      },
      {
        title: "Architect",
        uid: "Architect",
        path: "",
        type: "item",
        childrens: [
          {
            title: "Tax Benefit Plans",
            uid: "Tax Benefit Plans",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Tax benefit plans",
            uid: ROUTE_BASE_URL.TAX_BENEFIT_PLANS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TAX_BENEFIT_PLANS"]}`,
            type: "item",
            coreOperation: "Prod_usTbpList",
          },
          {
            title: "Plan type",
            uid: ROUTE_BASE_URL.PLAN_TYPE,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PLAN_TYPE"]}`,
            type: "item",
            coreOperation: "UsTbpTypeList",
          },
          {
            title: "Hierarchy",
            uid: "Hierarchy",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Product types",
            uid: ROUTE_BASE_URL.PRODUCT_TYPES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_TYPES"]}`,
            type: "item",
            coreOperation: "ProdTypeList",
          },
          {
            title: "Product subtypes",
            uid: ROUTE_BASE_URL.PRODUCT_SUB_TYPES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_SUB_TYPES"]}`,
            type: "item",
            coreOperation: "ProdSubTypeList",
          },
          {
            title: "Product groups",
            uid: ROUTE_BASE_URL.IFX_ACCT_TYPE,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IFX_ACCT_TYPE"]}`,
            type: "item",
            coreOperation: "ProdGroupList",
          },
          {
            title: "Components",
            uid: "Components",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Deposit charge-off components",
            uid: ROUTE_BASE_URL.DEPOSIT_CHARGE_OFF_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["DEPOSIT_CHARGE_OFF_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentDepChrgOffList",
          },
          {
            title: "Fee components",
            uid: ROUTE_BASE_URL.FEE_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["FEE_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentFeeList",
          },
          {
            title: "Interest components",
            uid: ROUTE_BASE_URL.INTEREST_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["INTEREST_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentIntList",
          },
          {
            title: "Limit components",
            uid: ROUTE_BASE_URL.LIMIT_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["LIMIT_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentLimitList",
          },
          {
            title: "Non-sufficient funds components",
            uid: ROUTE_BASE_URL.NON_SUFFICIENT_FUNDS_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["NON_SUFFICIENT_FUNDS_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentNsfList",
          },
          {
            title: "Repay components",
            uid: ROUTE_BASE_URL.REPAY_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["REPAY_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentRepayList",
          },
          {
            title: "Rewards components",
            uid: ROUTE_BASE_URL.REWARDS_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["REWARDS_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentRewardList",
          },
          {
            title: "Rule components",
            uid: ROUTE_BASE_URL.RULE_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["RULE_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentRulesList",
          },
          {
            title: "Term deposit components",
            uid: ROUTE_BASE_URL.TERM_DEPOSIT_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TERM_DEPOSIT_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentTdList",
          },
          {
            title: "Transaction components",
            uid: ROUTE_BASE_URL.TRANSACTION_COMPONENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TRANSACTION_COMPONENTS"]}`,
            type: "item",
            coreOperation: "ComponentTrnOptList",
          },
          {
            title: "Limits",
            uid: "Limits",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Transaction limits",
            uid: ROUTE_BASE_URL.TRANSACTION_LIMITS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TRANSACTION_LIMITS"]}`,
            type: "item",
            coreOperation: "TrnLimitList",
          },
          {
            title: "Accumulated transaction limits",
            uid: ROUTE_BASE_URL.ACCUMULATED_TRANSACTION_LIMITS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ACCUMULATED_TRANSACTION_LIMITS"]}`,
            type: "item",
            coreOperation: "AccumTrnLimitList",
          },
          {
            title: "Relationship rewards",
            uid: "Relationship Rewards",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Product programs",
            uid: ROUTE_BASE_URL.PRODUCT_PROGRAMS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_PROGRAMS"]}`,
            type: "item",
            coreOperation: "ProdProgramList",
          },
          {
            title: "Product program groups",
            uid: ROUTE_BASE_URL.PRODUCT_PROGRAM_GROUPS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_PROGRAM_GROUPS"]}`,
            type: "item",
            coreOperation: "ProdProgramGroupList",
          },
          {
            title: "Product program groups rel",
            uid: ROUTE_BASE_URL.PRODUCT_PROGRAM_GROUPS_REL,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_PROGRAM_GROUPS_REL"]}`,
            type: "item",
            coreOperation: "ProdProgramGroupRelList",
          },
          {
            title: "Other",
            uid: "Other",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Loan delinquency",
            uid: ROUTE_BASE_URL.LOAN_DELINQUENCY,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["LOAN_DELINQUENCY"]}`,
            type: "item",
            coreOperation: "Prod_bkLnDelqList",
          },
          {
            title: "Sweeps",
            uid: ROUTE_BASE_URL.SWEEPS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["SWEEPS"]}`,
            type: "item",
            coreOperation: "ProdSvcList",
          },
          {
            title: "Product services",
            uid: ROUTE_BASE_URL.PRODUCT_SERVICES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["PRODUCT_SERVICES"]}`,
            type: "item",
            coreOperation: "ProdSvcList",
          },
          {
            title: "utilities",
            uid: "Other",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Frequency modeler",
            uid: ROUTE_BASE_URL.FREQUENCY_MODELER,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["FREQUENCY_MODELER"]}`,
            type: "item",
            coreOperation: "CalcNextDt",
          },
          {
            title: "Clear cached core config",
            uid: ROUTE_BASE_URL.CLEAR_CACHE_CORE,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["CLEAR_CACHE_CORE"]}`,
            type: "item",
            coreOperation: ["ClearCache", "EmptyAllCache"],
            inclusive: true,
          },
          {
            title: "TGUID",
            uid: "TGUID",
            path: "",
            type: "item",
            childrens: [
              {
                title: "TGUID decoder",
                uid: ROUTE_BASE_URL.TGUID_DECODER,
                childrens: [],
                isLegacy: true,
                path: `${ROUTE_BASE_URL["TGUID_DECODER"]}`,
                type: "item",
                coreOperation: "ErrLogList",
              },
              {
                title: "TGUID encoder",
                uid: ROUTE_BASE_URL.TGUID_ENCODER,
                childrens: [],
                isLegacy: true,
                path: `${ROUTE_BASE_URL["TGUID_ENCODER"]}`,
                type: "item",
                coreOperation: "ErrLogList",
              },
            ],
          },
        ],
      },
      {
        title: "Test & Deploy",
        uid: "Test & Deploy",
        childrens: [],
        path: "",
        type: "header",
      },
      {
        title: "Diagnostics & implementation",
        uid: "Diagnostics & implementation",
        path: "",
        type: "item",
        childrens: [
          {
            title: "Implementation",
            uid: "Implementation",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Main dashboard",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_MAIN_DASHBOARD,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_MAIN_DASHBOARD"]}`,
            type: "item",
            consoleOperation: "project-view",
          },
          {
            title: "Projects",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_PROJECT,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_PROJECT"]}`,
            type: "item",
            consoleOperation: "project-view",
          },
          {
            title: "Builds",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_BUILDS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_BUILDS"]}`,
            type: "item",
            consoleOperation: "request-environment-deploys",
          },
          {
            title: "Deployments",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_DEPLOYMENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_DEPLOYMENTS"]}`,
            type: "item",
            consoleOperation: "request-environment-deploys",
          },
          {
            title: "Timeshifts",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_TIMESHIFTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_TIMESHIFTS"]}`,
            type: "item",
            consoleOperation: "request-environment-timeshifts",
          },
          {
            title: "Environment health",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_ENV_HEALTH,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_ENV_HEALTH"]}`,
            type: "item",
          },
          {
            title: "DSS log messages",
            uid: ROUTE_BASE_URL.IMPLEMENTATION_DSL_LOG_MESSAGES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["IMPLEMENTATION_DSL_LOG_MESSAGES"]}`,
            type: "item",
            consoleOperation: "project-view",
          },
          {
            title: "General",
            uid: "General",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Diagnostic dashboard",
            uid: ROUTE_BASE_URL.DIAGNOSTIC_DASHBOARD,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["DIAGNOSTIC_DASHBOARD"]}`,
            type: "item",
            coreOperation: [
              "SystemCalendarList",
              "PosnCalendarList",
              "EventCtxtList",
              "ErrLogList",
              "GetPosnCalendarMissingEvents",
            ],
            inclusive: true,
          },
          {
            title: "Error log",
            uid: ROUTE_BASE_URL.ERROR_LOG,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ERROR_LOG"]}`,
            type: "item",
            coreOperation: "ErrLogList",
          },
          {
            title: "Journal",
            uid: ROUTE_BASE_URL.JOURNAL,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["JOURNAL"]}`,
            type: "item",
            coreOperation: "JournalList",
          },
          {
            title: "Release notes",
            uid: ROUTE_BASE_URL.RELEASE_NOTES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["RELEASE_NOTES"]}`,
            type: "item",
          },
          {
            title: "Schema overview",
            uid: ROUTE_BASE_URL.SCHEMA_OVERVIEW,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["SCHEMA_OVERVIEW"]}`,
            type: "item",
          },
          {
            title: "Schema & choices",
            uid: ROUTE_BASE_URL.SCHEMA_AND_CHOICES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["SCHEMA_AND_CHOICES"]}`,
            type: "item",
          },
          {
            title: "Announcements",
            uid: ROUTE_BASE_URL.ANNOUNCEMENTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ANNOUNCEMENTS"]}`,
            type: "item",
          },
          {
            title: "Balancing & reconciliation",
            uid: "Balancing & Reconciliation",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "GL balancing",
            uid: ROUTE_BASE_URL.GL_BALANCING,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["GL_BALANCING"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "gl-balancing-list",
          },
          {
            title: "Batch reconciliation",
            uid: ROUTE_BASE_URL.BATCH_RECONCILIATION,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["BATCH_RECONCILIATION"]}`,
            type: "item",
            coreOperation: "ReconBatch",
          },
          {
            title: "Transaction reconciliation",
            uid: ROUTE_BASE_URL.TRANSACTION_RECONCILIATION,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TRANSACTION_RECONCILIATION"]}`,
            type: "item",
            coreOperation: "ReconTrn",
          },
          {
            title: "Events",
            uid: "Events",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "System calendar",
            uid: ROUTE_BASE_URL.SYSTEM_CALENDER,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["SYSTEM_CALENDER"]}`,
            type: "item",
            coreOperation: "SystemCalendarList",
          },
          {
            title: "Posn calendar",
            uid: ROUTE_BASE_URL.POSN_CALENDER,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["POSN_CALENDER"]}`,
            type: "item",
            coreOperation: "PosnCalendarList",
          },
          {
            title: "Release calendar",
            uid: ROUTE_BASE_URL.RELEASE_CALENDER,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["RELEASE_CALENDER"]}`,
            type: "item",
          },
          {
            title: "Event context",
            uid: ROUTE_BASE_URL.EVENT_CONTEXT,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["EVENT_CONTEXT"]}`,
            type: "item",
            coreOperation: "EventCtxtList",
          },
        ],
      },
      {
        title: "Operations",
        uid: "Operations",
        path: "",
        type: "item",
        childrens: [
          {
            title: "Ach Corporate Origination",
            uid: "Ach Corporate Origination",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Files",
            uid: ROUTE_BASE_URL.FILES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["FILES"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "ach-file-list",
          },
          {
            title: "ACH Batch",
            uid: ROUTE_BASE_URL.ACH_BATCH,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ACH_BATCH"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "ach-file-list",
          },
          {
            title: "Batch exceptions",
            uid: ROUTE_BASE_URL.BATCH_EXCEPTIONS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["BATCH_EXCEPTIONS"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "ach-file-list",
          },
          {
            title: "Batch file management",
            uid: "Batch File Management",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Batch information",
            uid: ROUTE_BASE_URL.BATCH_INFORMATION,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["BATCH_INFORMATION"]}`,
            type: "item",
            coreOperation: "BatchList",
          },
          {
            title: "ACH files",
            uid: ROUTE_BASE_URL.ACH_FILES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ACH_FILES"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "ach-file-list",
          },
          {
            title: "Check files",
            uid: ROUTE_BASE_URL.CHECK_FILES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["CHECK_FILES"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "check-file-list",
          },
          {
            title: "Core batch files",
            uid: ROUTE_BASE_URL.CORE_BATCH_FILES,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["CORE_BATCH_FILES"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "core-batch-file-list",
          },
          {
            title: "Orders",
            uid: "Orders",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "ACH orders",
            uid: ROUTE_BASE_URL.ACH_ORDERS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ACH_ORDERS"]}`,
            type: "item",
            coreOperation: "OrderList",
          },
          {
            title: "Core orders",
            uid: ROUTE_BASE_URL.CORE_ORDERS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["CORE_ORDERS"]}`,
            type: "item",
            coreOperation: "OrderList",
          },
          {
            title: "Wire orders",
            uid: ROUTE_BASE_URL.WIRE_ORDERS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["WIRE_ORDERS"]}`,
            type: "item",
            coreOperation: "OrderList",
          },
          {
            title: "Reports & extracts",
            uid: "Reports & Extracts",
            childrens: [],
            path: "",
            type: "header",
          },
          {
            title: "Reports",
            uid: ROUTE_BASE_URL.REPORTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["REPORTS"]}`,
            type: "item",
            consoleOperation: "reports-file-list",
          },
          {
            title: "GL extracts",
            uid: ROUTE_BASE_URL.GL_EXTRACTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["GL_EXTRACTS"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "gl-extracts-file-list",
          },
          {
            title: "Escheats extracts",
            uid: ROUTE_BASE_URL.ESCHEATS_EXTRACTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ESCHEATS_EXTRACTS"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "escheat-extracts-file-list",
          },
          {
            title: "Open exception extracts",
            uid: ROUTE_BASE_URL.OPEN_EXCEPTION_EXTRACTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["OPEN_EXCEPTION_EXTRACTS"]}`,
            type: "item",
            coreOperation: "ListFiles",
            consoleOperation: "open-exceptions-extracts-file-list",
          },
          {
            title: "Tax files & extracts",
            uid: ROUTE_BASE_URL.TAX_FILES_AND_EXTRACTS,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["TAX_FILES_AND_EXTRACTS"]}`,
            type: "item",
            coreOperation: "TaxFileList",
          },
          {
            title: "ACH FED reversal",
            uid: ROUTE_BASE_URL.ACH_FED_REVERSAL,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["ACH_FED_REVERSAL"]}`,
            type: "item",
            inclusive: true,
            coreOperation: [
              "SystemCalendarList",
              "ReverseOutgoingACHFile",
              "ReverseOutgoingACHEntry",
              "ReverseOutgoingACHBatch",
            ],
          },
          {
            title: "FDIC 370",
            uid: ROUTE_BASE_URL.FDIC_370,
            childrens: [],
            isLegacy: true,
            path: `${ROUTE_BASE_URL["FDIC_370"]}`,
            type: "item",
            coreOperation: "BankClose",
          },
        ],
      },
      {
        title: "Swagger",
        uid: ROUTE_BASE_URL.SWAGGER,
        childrens: [],
        isLegacy: true,
        path: `${ROUTE_BASE_URL["SWAGGER"]}`,
        type: "item",
      },
      {
        title: "Core servicing",
        uid: ROUTE_BASE_URL.CORE_SERVICING,
        childrens: [],
        isLegacy: true,
        path: `${ROUTE_BASE_URL["CORE_SERVICING"]}`,
        type: "item",
      },
      {
        title: "",
        uid: "Requestservice",
        childrens: [],
        path: "",
        type: "header",
      },
      {
        title: "Request service",
        uid: ROUTE_BASE_URL.REQUEST_SERVICE,
        childrens: [],
        isLegacy: true,
        path: `${ROUTE_BASE_URL["REQUEST_SERVICE"]}`,
        type: "item",
        icon: { src: `${spanner.src}` },
      },
    ],
  },
];

export {
  SIDEBAR_MENU,
  ACADEMY_SIDEBAR_MENU,
  Global_SIDEBAR_MENU,
  ACADEMY_SIDEBAR_MENU_MULTI,
};
