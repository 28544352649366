"use client";

import {
  CardContainer,
  ArrowForwardIcon,
  IconLinkButton,
} from "../../../common";
import {
  Card,
  CardBody,
  CardFooter,
  Text,
  Heading,
  SimpleGrid,
  Button,
  Stack,
  Image,
} from "@chakra-ui/react";
import "./DocCenterExplore.scss";
import { ROUTE_PATH } from "@/route-config/route-path";
import { exploreImage } from "public/assets";

type PageModal = {
  id: string;
  uid: string;
  url: string;
  type: string;
  href: string;
  tags: string[];
  first_publication_date: string;
  last_publication_date: string;
  slugs: string[];
  linked_documents: string[];
  lang: string;
  alternate_languages: string[];
  data: {
    document_title: string;
    categories: [[Object]];
    slices: [[Object], [Object]];
    meta_description: null;
    meta_image: {};
    meta_title: null;
  };
};

type DocumentCenterModalProps = {
  title: string;
  subTitle: string;
  docList: any[];
};

const DocCenterExplore = (props: DocumentCenterModalProps) => {
  const { title, subTitle, docList } = props;
  return (
    <CardContainer customClass="doc-explore-container">
      <CardBody padding={2}>
        <Stack className="inner-container">
          <Stack gap={3}>
            <Heading className="doc-heading">{title}</Heading>
            <Text className="doc-sub-heading">{subTitle}</Text>
          </Stack>

          <SimpleGrid
            gap="var(--chakra-space-10)"
            columns={{ sm: 1, md: 2, lg: 2, xl: 3 }}
          >
            {docList.map((page: any, index) => (
              <Card key={index} className="card">
                <CardBody padding={3}>
                  <Stack spacing="7">
                    <Image
                      src={
                        page.card_image?.url ||
                        page.link?.data?.meta_image?.url ||
                        exploreImage.src
                      }
                      alt={page.card_image?.alt || "explore card"}
                      borderRadius="lg"
                    />
                    <Heading size="lg">
                      {page.link?.data?.article_main_title?.length > 0
                        ? page.link?.data?.article_main_title[0]?.text
                        : page.link?.data?.meta_title || page.title}
                    </Heading>
                    <Text size="sm">
                      {page.description || page.link?.data?.meta_description}
                    </Text>
                  </Stack>
                </CardBody>
                <CardFooter padding={3}>
                  <IconLinkButton
                    link={
                      page.link?.link_type === "Web"
                        ? page.link?.url
                        : `${ROUTE_PATH["DOCUMENT_DETAIL"]}/${page.link?.type}/${page.link?.uid}`
                    }
                    rightIcon={<ArrowForwardIcon />}
                    text={page.anchor_text}
                  />
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </Stack>
      </CardBody>
    </CardContainer>
  );
};

export default DocCenterExplore;
