import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import Image from "next/image";
import { dashboardEnvUpgradeIcon } from "public/assets";
import React, { useEffect, useRef, useState } from "react";
import "./EnvironmentContainer.scss";
import { useSelector } from "react-redux";
import { getEnv, getEnvId, getEnvName, getSelectedOrg } from "@/store";
import { AppFormLabel, AppModal, InputText } from "@/components/common";
import Link from "next/link";
import { API_ROUTE_CONFIGURATION } from "@/api-config";
import { ROUTE_PATH } from "@/route-config/route-path";
import { EnvStatus } from "@/store/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { ORG_PHASE } from "@/utils";
interface EnvironmentContainerProps {
  envData: EnvStatus[];
  upgradeVersionLink: any;
}

const EnvironmentContainer = (props: EnvironmentContainerProps) => {
  const { envId, envType, version } = useSelector(getEnv);
  const { envData, upgradeVersionLink } = props;
  const currentOrg = useSelector(getSelectedOrg);
  const isProspect = currentOrg?.phase === ORG_PHASE.prospect;
  const envName = useSelector(getEnvName);

  const [newVersionAvailable, setNewVersionAvailable] =
    useState<boolean>(false);

  const appInputModalRef = useRef<any>();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [organization, setOrganization] = useState<string>("");
  const [requestEntered, setRequestEntered] = useState<string>("");
  const [lastestVersion, setLatestVersion] = useState<string>("");

  useEffect(() => {
    (async () => {
      await fetch(
        `${envType === "proj" ? API_ROUTE_CONFIGURATION.project : API_ROUTE_CONFIGURATION.environment}${envId}/next-versions`
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw "Error";
        })
        .then((res) => {
          if (res?.upgradeVersions?.length) {
            setNewVersionAvailable(res.upgradeVersions.length > 0);
            const lastestVer = res.upgradeVersions.sort()?.at(-1);
            if (lastestVer) {
              setLatestVersion(lastestVer);
            }
          } else {
            setNewVersionAvailable(false);
          }
        })
        .catch(() => setNewVersionAvailable(false));
    })();
  }, []);

  async function onModalInputSave() {
    if (organization.length === 0) {
      return;
    }

    // unset dropdown and input values
    setRequestEntered("");
    appInputModalRef?.current?.closeModal();
  }

  function onModalInputCancel() {
    // Reset modal input value
    setRequestEntered("");
  }

  const otherEnv = envData.filter((data: any) => data.id !== envId);
  let otherVersion: any[] = [];
  if (otherEnv.length > 0) {
    otherVersion = otherEnv.map((data: any) => ({
      version: data.version ? data?.version?.version?.slice(1) : "UNKNOWN",
      name: data.name,
    }));
  }
  return (
    <>
      <Box className="environment-container">
        {envId != null ? (
          <Box className="current-env">
            <Heading as={"h5"} className="env-heading">
              Current environment
            </Heading>
            <Box>
              <Flex className="current-env-details">
                {version ? (
                  <FontAwesomeIcon
                    className="env-status-success"
                    icon={faCircleCheck}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="env-status-err"
                    icon={faCircleExclamation}
                  />
                )}
                <Text className="env-name">{envName}</Text>
              </Flex>
              {version && !isProspect ? (
                <Text className="env-name-desc">{version}</Text>
              ) : (
                ""
              )}
            </Box>
            {newVersionAvailable && !isProspect ? (
              <Flex className="env-upgrade">
                <Box>
                  <Image src={dashboardEnvUpgradeIcon} alt="Upgrade version" />
                </Box>
                <Link
                  href={`${ROUTE_PATH["DOCUMENT_DETAIL"]}?docId=${upgradeVersionLink?.uid}&docType=${upgradeVersionLink?.type}`}
                >
                  <Text className="env-upgrade-text">Upgrade version</Text>
                </Link>
              </Flex>
            ) : (
              ""
            )}
            {lastestVersion && !isProspect ? (
              <Box mt={5}>
                <Text className="font-size-12px">
                  Core version {lastestVersion}
                </Text>
              </Box>
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        {otherVersion?.length ? (
          <Box className="current-env other-info">
            <Heading as={"h6"} className="other-env-heading">
              Available environments
            </Heading>
            {otherVersion.map((data: any, index: number) => (
              <Box key={index}>
                <Flex className="current-env-details">
                  {data.version === "UNKNOWN" ? (
                    <FontAwesomeIcon
                      className="env-status-err"
                      icon={faCircleExclamation}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="env-status-success"
                      icon={faCircleCheck}
                    />
                  )}
                  <Text className="env-name">{data.name}</Text>
                </Flex>
                <Text className="env-name-desc">{data.version}</Text>
              </Box>
            ))}
            {/* Request new enviroment removed as per FDT-1064 */}
          </Box>
        ) : (
          ""
        )}
      </Box>
      <AppModal
        customClass="app-modal-selectors"
        ref={appInputModalRef}
        modalTitle={"Speak with Finxact"}
        primaryBtnProp={{
          name: "Submit request",
          btnClassName: "app-btn-inverse-secondary",
        }}
        primaryBtnDisabled={
          firstName.trim() === "" ||
          lastName.trim() === "" ||
          email.trim() === "" ||
          organization.toString().trim() === ""
        }
        primaryBtnSelect={onModalInputSave}
        secondaryBtnSelect={onModalInputCancel}
        secondaryBtnProp={{
          name: "Cancel",
          btnClassName: "app-btn-link",
          style: { marginRight: "1rem" },
        }}
      >
        <Box className="app-form-field-container-small">
          <Text>
            Let’s chat. We’d love to share more about our value offering and
            answer any questions you may have.{" "}
          </Text>
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"First name"} isRequired={true} />
          <InputText
            value={firstName}
            onChange={setFirstName}
            disabled={Boolean(firstName)}
          />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Last name"} isRequired={true} />
          <InputText
            value={lastName}
            onChange={setLastName}
            disabled={Boolean(lastName)}
          />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Email"} isRequired={true} />
          <InputText
            value={email}
            onChange={setEmail}
            disabled={Boolean(email)}
          />
        </Box>
        <Box className="app-form-field-container-small">
          <AppFormLabel labelName={"Organization"} isRequired={true} />
          <InputText
            value={organization}
            onChange={setOrganization}
            disabled={Boolean(organization)}
          />
        </Box>
        <Box className="app-form-field-container">
          <AppFormLabel labelName={"Enter request message"} />
          <InputText
            textarea
            value={requestEntered}
            onChange={setRequestEntered}
          />
        </Box>

        <Divider />
      </AppModal>
    </>
  );
};

export default EnvironmentContainer;
